.App {
  font-family: sans-serif;
  text-align: center;
}

.main {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 141.4286%;
  padding-bottom: 48px;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
  /* transform: scale(1.04319); */
}
@media screen and (min-width: 72rem) {
  .main {
    padding-top: 99rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    max-width: 70rem;
  }
}

.frame {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}
